import { Component } from '@angular/core';

@Component({
  selector: 'sb-install-economic',
  templateUrl: './economic.component.html',
  styleUrls: ['./economic.component.scss'],
})
export class EconomicComponent {
  constructor() {}
}
