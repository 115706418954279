import { Injectable } from '@angular/core';
import * as BootstrapActions from '@app/main-store/bootstrap.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CompanyBootstrap, ResponseObject } from '@app/_shared/interfaces';
import { CompaniesService, StoreSettingsService, StoresService } from '@app/_shared/service';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { selectQueryParam } from '@app/main-store/router.selector';
import { WarningDialogService } from '@app/_shared/components/dialogs/warning-dialog/warning-dialog.service';
import { SnackBarService } from '@app/_shared/snack-bars/snack-bar.service';

export const DEFAULT_STORE_ID_STORAGE_KEY = 'storebuddy:defaultStoreId';

@Injectable()
export class BootstrapEffects {
  loadCompanyBootstrap = createEffect(() =>
    this._actions$.pipe(
      ofType(BootstrapActions.loadCompanyBootstrap),
      switchMap(() => {
        const params = new HttpParams().set('includes[]', 'stores');

        return this._companyService.index(params).pipe(
          map((data: ResponseObject<CompanyBootstrap>) => BootstrapActions.loadCompanyBootstrapSuccess(data)),
          catchError(() => of(BootstrapActions.loadCompanyBootstrapFailed())),
        );
      }),
    ),
  );

  setDefaultStoreOnInit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BootstrapActions.loadCompanyBootstrapSuccess),
      concatLatestFrom(() => this._store.select(selectQueryParam('default_store_id'))),
      map(([data, defaultStoreIdFromQuery]) => {
        const defaultStoreId = defaultStoreIdFromQuery
          ? +defaultStoreIdFromQuery
          : +localStorage.getItem(DEFAULT_STORE_ID_STORAGE_KEY);
        const hasValidStore = data.data.attributes.stores
          .filter((store) => !store.attributes.deleted)
          .some((store) => +store.id === defaultStoreId);
        return BootstrapActions.setDefaultStoreById({ storeId: hasValidStore ? defaultStoreId : null });
      }),
    ),
  );

  setDefaultStoreIdDirectInLocalStorage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BootstrapActions.setDefaultStoreIdLocalstorage),
        tap(({ storeId }) => localStorage.setItem(DEFAULT_STORE_ID_STORAGE_KEY, storeId.toString())),
      ),
    { dispatch: false },
  );

  updateDefaultStoreIdInLocalStorage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BootstrapActions.setDefaultStoreById),
        tap((action) => {
          if (action.storeId === null || action.storeId === 0) {
            localStorage.removeItem(DEFAULT_STORE_ID_STORAGE_KEY);
            return;
          }
          localStorage.setItem(DEFAULT_STORE_ID_STORAGE_KEY, action.storeId.toString());
        }),
      ),
    { dispatch: false },
  );

  updateCompany$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(BootstrapActions.updateCompany),
      switchMap((action) => {
        return this._companyService.patch(action.data).pipe(
          map((response) => BootstrapActions.updateCompanySuccess({ data: response.data })),
          catchError((error) => of(BootstrapActions.updateCompanyFailed(error.error))),
        );
      }),
    );
  });

  updateStores$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(BootstrapActions.updateStore),
      switchMap((action) => {
        return this._storesService.patch(action.id, action.data).pipe(
          map((response) => BootstrapActions.updateStoreSuccess({ data: response.data })),
          catchError((error) => of(BootstrapActions.updateStoreFailed(error.error))),
        );
      }),
    );
  });

  onUpdateStoreFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BootstrapActions.updateStoreFailed),
        tap((action) => this._snackBarService.openFailure({ body: action.message })),
      ),
    { dispatch: false },
  );

  updateStoreSettings$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(BootstrapActions.updateStoreSettings),
      switchMap((action) => {
        return this._storeSettingsService.patch(action.storeId, action.data).pipe(
          map((response) => BootstrapActions.updateStoreSettingsSuccess({ data: response.data })),
          catchError((error) => of(BootstrapActions.updateStoreSettingsFailed(error.error))),
        );
      }),
    );
  });

  onUpdateStoreSettingsFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BootstrapActions.updateStoreSettingsFailed),
        tap((action) =>
          this._dialogService.open({
            data: {
              title: 'Info',
              message: action.message,
              confirmText: 'Ok',
              variant: 'primary',
            },
          }),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _companyService: CompaniesService,
    private _store: Store,
    private _storesService: StoresService,
    private _storeSettingsService: StoreSettingsService,
    private _routerState: ActivatedRoute,
    private _dialogService: WarningDialogService,
    private _snackBarService: SnackBarService,
  ) {}
}
