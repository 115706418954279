import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstallComponent } from './oauth/install/install.component';
import { StoresCreatePageComponent } from './stores/create/layouts/page/page.component';
import { SalesPlatformConnectComponent } from './stores/sales-platform-connect/sales-platform-connect.component';
import { SalesPlatformConnectGuard } from './stores/sales-platform-connect/sales-platform-connect.guard';
import { SalesPlatformComponent } from './stores/sales-platform/sales-platform.component';
import { OnboardingSuccessPageComponent } from './success/layouts/page/page.component';
import { OnboardingSuccessGuard } from './success/layouts/page/page.guard';
import { installResolver } from '@app/onboarding/oauth/install/install.resolver';
import { pageResolver } from '@app/onboarding/stores/create/layouts/page/page.resolver';

export const routes: Routes = [
  {
    path: 'oauth/install',
    component: InstallComponent,
    resolve: [installResolver],
  },
  {
    path: 'stores/create',
    component: StoresCreatePageComponent,
    resolve: [pageResolver],
  },
  {
    path: 'stores/sales-platform',
    component: SalesPlatformComponent,
  },
  {
    path: 'stores/sales-platform/connect',
    component: SalesPlatformConnectComponent,
    canActivate: [SalesPlatformConnectGuard],
  },
  {
    path: 'onboarding/success',
    component: OnboardingSuccessPageComponent,
    canActivate: [OnboardingSuccessGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OnboardingRoutesModule {}
