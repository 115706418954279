import { ResolveFn } from '@angular/router';
import * as OnboardingActions from '@app/onboarding/store/onboarding.actions';
import { combineLatest, first, map } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

export const pageResolver: ResolveFn<boolean> = () => {
  inject(Store).dispatch(OnboardingActions.getPlans());

  return combineLatest([ofGetPlansSuccessOrFailure()]).pipe(
    first(),
    map(() => true),
  );
};

const ofGetPlansSuccessOrFailure = () =>
  inject(Actions).pipe(ofType(OnboardingActions.getPlansSuccess, OnboardingActions.getPlansFailure));
