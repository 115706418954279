import { Component, OnInit } from '@angular/core';
import { DEFAULT_STORE_ID_STORAGE_KEY } from '@app/main-store/bootstrap.effects';
import { IntegrationPlatform } from '@app/onboarding/store/integrations.service';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs';
import { OAuthActions } from '../oauth/install/install.service';
import { Flow } from '../store/onboarding.reducer';
import * as OnboardingSelectors from '../store/onboarding.selector';
import { DataObject, Subscription } from '@app/_shared/interfaces';

@Component({
  selector: 'sb-onboarding-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class OnboardingSuccessComponent implements OnInit {
  public storeId: string | null = null;
  public platform: IntegrationPlatform | null = null;
  public action: OAuthActions | null = null;
  public flow: Flow | null = null;
  public subscriptions: DataObject<Subscription>[] = [];

  constructor(private _store: Store) {}

  ngOnInit() {
    this.storeId = localStorage.getItem(DEFAULT_STORE_ID_STORAGE_KEY);
    this._store.pipe(select(OnboardingSelectors.selectFlow), first()).subscribe((flow) => {
      this.flow = flow;
    });

    this._store.pipe(select(OnboardingSelectors.selectOAuthState), first()).subscribe((oAuthState) => {
      this.platform = oAuthState.platform;
      this.action = oAuthState.action;
    });

    this._store.pipe(select(OnboardingSelectors.selectSubscriptions), first()).subscribe((subscriptions) => {
      this.subscriptions = subscriptions;
    });
  }
}
