<div class="install-container">
  @if ((loading$ | async) === false && createFlowDone === false) {
    <mat-card>
      @switch ((oAuthState$ | async)?.platform) {
        @case ('dinero') {
          <sb-install-dinero></sb-install-dinero>
        }
        @case ('onpay') {
          <sb-install-onpay></sb-install-onpay>
        }
        @case ('economic') {
          <sb-install-economic></sb-install-economic>
        }
        @case ('stripe') {
          <sb-stripe-connect></sb-stripe-connect>
        }
        @case ('zenegy') {
          <sb-zenegy></sb-zenegy>
        }
      }
      @if (installingIntegration) {
        <mat-card-footer>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      }
    </mat-card>
  }
  @if ((loading$ | async) === false && createFlowDone === true) {
    <mat-card>
      <mat-card-content>
        <sb-onboarding-success></sb-onboarding-success>
      </mat-card-content>
    </mat-card>
  }
  @if ((loading$ | async) === true && createFlowDone === false) {
    <mat-card>
      <mat-card-content class="d-flex flex-column justify-content-center align-items-center">
        <mat-spinner [diameter]="50" class="mt-5"></mat-spinner>
        <p class="mt-3 mb-5 fw-bold text-black-50">Giv os et øjeblik..</p>
      </mat-card-content>
    </mat-card>
  }
</div>
