<sb-logo-layout-wrapper>
  <div class="sales-platform-connect-container">
    <h1 class="text-center mb-3">Tilknyt din salgskanal</h1>
    <p class="text-center mb-5">Udfyld informationerne herunder for at komme igang</p>
    @switch (salesPlatform$ | async) {
      @case ('shopify') {
        <sb-shopify-connect></sb-shopify-connect>
      }
    }
  </div>
</sb-logo-layout-wrapper>
