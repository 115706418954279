<div class="container-fluid">
  <div class="row min-vh-100">
    <div class="col-12 col-md-6 d-flex justify-content-center align-items-center border-end border-bottom bg-light p-5">
      <img src="/assets/logo/logo-v2.svg" class="w-50" />
    </div>
    <div class="position-relative d-flex flex-column justify-content-center col-sm-12 col-md-6 p-5 bg-white">
      <a mat-icon-button [routerLink]="['/']" class="fa-icon-button">
        <fa-icon [icon]="['fal', 'times']" size="lg"></fa-icon>
      </a>
      <ng-content></ng-content>
    </div>
  </div>
</div>
