import { Component } from '@angular/core';

@Component({
  selector: 'sb-install-onpay',
  templateUrl: './onpay.component.html',
  styleUrls: ['./onpay.component.scss'],
})
export class OnpayComponent {
  constructor() {}
}
