<mat-card-content>
  <mat-card-subtitle>Storebuddy ApS</mat-card-subtitle>
  <mat-card-title>Vælg det firma du ønsker at tilknytte</mat-card-title>
  <mat-card-subtitle class="mt-1">
    For at installere integrationen skal den tilknyttes et firma fra Visma Dinero. Vælg et firma herunder.
  </mat-card-subtitle>
  <mat-form-field appearance="fill" class="w-100 mt-3">
    <mat-label>Vælg firma</mat-label>
    <mat-select (valueChange)="onOrganizationSelect($event)">
      @for (organization of organizations$ | async; track organization) {
        <mat-option [value]="organization.id">
          {{ organization.attributes.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <button
    mat-flat-button
    color="primary"
    [disabled]="selectedOrganizationId === null || installingIntegration"
    (click)="onInstallIntegration()"
    class="w-100"
  >
    Installer integration
  </button>
</mat-card-content>
