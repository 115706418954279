import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntegrationCategory, IntegrationPlatform } from '@app/onboarding/store/integrations.service';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { DataGeneric } from '@app/_interface/data';

export enum OAuthActions {
  FRESH_INSTALL = 'fresh-install',
  RE_INSTALL = 're-install',
  APP_FRESH_INSTALL = 'app-fresh-install',
  APP_RE_INSTALL = 'app-re-install',
}

interface OAuthStateResponse {
  id: string;
  type: string;
  attributes: {
    action: OAuthActions;
    category: IntegrationCategory;
    platform: IntegrationPlatform;
    store_id: number | null;
    api_integration_id: number | null;
  };
}

export interface Store {
  attributes: {
    created_at: string;
    current_period_end: string;
    current_period_start: string;
    interval: string;
    is_deleted: boolean;
    name: string;
    next_reset_at: string;
    subscription_status: string;
    updated_at: string;
    version: number;
  };
  id: number;
  type: 'stores';
}

export type OnboardingStore = Store & {
  attributes: {
    has_integration: boolean;
    integration_id: number | null;
  };
};

export interface ApiIntegration {
  id: number;
  type: 'api-integrations';
  attributes: {
    store_id: number;
    host: string;
    url: string;
    ssl: boolean;
    category: string;
    platform: string;
    version: string;
    created_at: string;
    updated_at: string;
    store: Store;
  };
}

export interface DineroOrganization {
  id: number;
  type: 'organizations';
  attributes: {
    name: string;
  };
}

@Injectable({ providedIn: 'root' })
export class InstallService {
  constructor(private _httpClient: HttpClient) {}

  getOAuthState(stateToken: string) {
    const headers = new HttpLaravelHeaders();
    return this._httpClient.get<DataGeneric<null, OAuthStateResponse>>(`/api/v1/user/oauth/states/${stateToken}`, {
      headers,
    });
  }

  getStoresWithoutWebshopIntegration() {
    const headers = new HttpLaravelHeaders();
    const params = new HttpParams().set('filter[api_integration.category:none]', 'webshop');

    return this._httpClient.get<DataGeneric<null, Store[]>>(`/api/v1/user/stores`, { headers, params });
  }

  getDineroOrganizations(storeId: number) {
    const headers = new HttpLaravelHeaders();
    const params = new HttpParams().set('filter[store_id]', storeId);

    return this._httpClient.get<DataGeneric<null, DineroOrganization[]>>('/api/v1/user/organizations', {
      headers,
      params,
    });
  }

  getIntegrationsByUrl(url: string) {
    const headers = new HttpLaravelHeaders();
    const params = new HttpParams()
      .append('filter[url]', url)
      .append('filter[category]', 'webshop')
      .append('includes', 'store');

    return this._httpClient.get<DataGeneric<null, ApiIntegration[]>>(`/api/v1/user/api-integrations`, {
      headers,
      params,
    });
  }
}
