import { InjectionToken } from '@angular/core';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromOnboardingReducer from './onboarding.reducer';

export const onboardingFeatureKey = 'onboarding';

export interface State {
  onboarding: fromOnboardingReducer.State;
}

export const reducers: ActionReducerMap<State> = {
  onboarding: fromOnboardingReducer.reducer,
};

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>('Onboarding Reducers');

export function getReducers(): ActionReducerMap<State> {
  return reducers;
}

export const getOnboardingRootState = createFeatureSelector<State>(onboardingFeatureKey);
