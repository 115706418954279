import { createSelector } from '@ngrx/store';
import { getOnboardingRootState } from '.';

export const selectOnboardingState = createSelector(getOnboardingRootState, (state) => state.onboarding);
export const selectOnboardingStoreId = createSelector(selectOnboardingState, (state) => state.store_id);
export const selectLoading = createSelector(selectOnboardingState, (state) => state.loading);
export const selectSalesPlatform = createSelector(selectOnboardingState, (state) => state.oAuthState.platform);
export const selectStores = createSelector(selectOnboardingState, (state) => state.stores);
export const selectOAuthState = createSelector(selectOnboardingState, (state) => state.oAuthState);
export const selectQueryParams = createSelector(selectOnboardingState, (state) => state.queryParams);
export const selectFlow = createSelector(selectOnboardingState, (state) => state.flow);
export const selectPlans = createSelector(selectOnboardingState, (state) => state.plans);
export const selectSubscriptions = createSelector(selectOnboardingState, (state) => state.subscriptions);
export const selectAlert = createSelector(selectOnboardingState, (state) => state.alert);
export const selectOrganizations = createSelector(selectOnboardingState, (state) => state.organizations);
