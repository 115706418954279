import { Component } from '@angular/core';

@Component({
  selector: 'sb-stripe-connect',
  templateUrl: './stripe-connect.component.html',
  styleUrls: ['./stripe-connect.component.scss'],
})
export class StripeConnectComponent {
  constructor() {}
}
